import React from "react";

import Layout from "../layouts/zh";
import SEO from "../components/seo";

import { AccountBlocked } from "../components/TWComponents/AccountBlocked";

const AccountBlockedPage = (props) => {
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Account Deactivated"
        keyword="Account Deactivated"
        decription="Account Deactivated"
      />
      <AccountBlocked
        title="账户已停用"
        subDesciption="您的账户已停用。这可能是由于24个月内没有活动、注册时使用了无效的电子邮件或与其他账户合并造成的。"
        decription='请<a href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Account%20Reactivation" target="_top" className="!tw-text-[#0095D8]">联系我们</a> 以重新激活您的账户或获得帮助。'
        button="Account Deactivated"
      />
    </Layout>
  );
};

export default AccountBlockedPage;
